import "./circle.css";
import React from "react";

export const Circle = () => {
  return (
    <>
      <div className="circle-box">
        <div className="circle" />
      </div>
    </>
  );
};
